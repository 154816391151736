import React from 'react';

const ChoosePage = () => {
  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-50">
      <div className="text-center">
        <h1 className="text-3xl font-semibold text-gray-800 mb-6">Choose Your Page</h1>
        
        <div className="flex justify-center gap-4">
          {/* Customer Button */}
          <button
            onClick={() => window.location.href = '/admin/addcustomer'}
            className="px-6 py-3 bg-blue-600 text-white text-lg font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all transform hover:scale-105"
          >
            Customer Page
          </button>

          {/* Admin Button */}
          <button
            onClick={() => window.location.href = '/admin/user'}
            className="px-6 py-3 bg-green-600 text-white text-lg font-medium rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition-all transform hover:scale-105"
          >
            Admin Page
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChoosePage;
