import React from "react";
import { useAuth } from "../../context/AuthContext";
import LoginForm from "../../components/Login/LoginForm";
 // Ensure this component handles the customer_id prop
import { useParams } from "react-router-dom";
import Admincustomer from "../../components/AdminMenu/Admincustomer";
import DocumentListByid from "../../components/DocumentList/DocumentListByid";
import Unauthorized from "../../components/Unauthorized/Unauthorized";


function DocumentListBy() {
  // Destructure the auth hook
  const { isLogged, isAdmin, user, isemployee, ismanager, isuser} = useAuth();
  // Get customer_id from URL parameters
  // const { user_id } = useParams();
  console.log`("Customer ID from URL:", {user.user_id})`;
  if (!isLogged) {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }

  if (!isAdmin || !isemployee || !ismanager || !isuser) {
    return (
      <div>
        <Unauthorized/>
      </div>
    );
  }

  return (
    <div className="container-fluid admin-pages">
      <div className="row">
        <div className="col-md-3 admin-left-side">
          {/* Pass customer_id to AdminMenu component */}
          <Admincustomer user_id={user.user_id} />
        </div>
        <div className="col-md-9 admin-right-side">
          {/* Pass customer_id to CustomDeclarationsList component */}
          <DocumentListByid user_id={user.user_id} />
        </div>
      </div>
    </div>
  );
}

export default DocumentListBy;
