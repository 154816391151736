import React from "react";
import { Routes, Route } from "react-router-dom";

// Pages
import Home from "./pages/home/index.js";
import About from "./pages/about/index.js";
import Services from "./pages/services/index.js";
import Project from "./pages/project/index.js";
import Contact from "./pages/contact/index.js";
import Login from "./pages/Login/Login.js";
import Register from "./pages/Register/Register.js";
import AddCustomer from "./pages/AddCustomer/AddCustomer.js";
import EditUser from "./pages/EditUser/EditUser.js";
import Users from "./pages/users/Users.js";
import Document from "./pages/document/Document.js";
import Custom from "./pages/custom/Custom.js";
import Documents from "./pages/AllDocuments/DocumentLists.js";
import CustomLists from "./pages/CustomLists/CustomLists.js";
import AdminMenu21 from "./pages/AddCustomer/AdminMenu21.js"; // Remove duplicate import
import Dashboard from "./components/Dashboard/Dashboard.js";
import AdminCustomList from "./pages/CustomLists/AdminCustomLists.js";
import UserList from "./pages/UserList/UserList21.js";
import DocumentListByid from "./pages/AllDocuments/DocumentListByid.js";
import AdminStatus from "./pages/AdminStatus/AdminStatus.js";

// Components
import Header from "./components/Layout/Header/index.js";
import Footer from "./components/Layout/Footer/index.js";
import PrivateAuthRoute from "./components/Auth/PrivateAuthRoute.js";
import 'bootstrap/dist/css/bootstrap.min.css';

// Assets
// import "./assets/template_assets/css/bootstrap.css";
import "./assets/template_assets/css/style.css";
import "./assets/template_assets/css/responsive.css";
import "./assets/template_assets/css/color.css";
import "./assets/css/Custom.css";
import Unauthorized from "./components/Unauthorized/Unauthorized.js";
import NotFound from "./components/NotFound/NotFound.js";
import ChoosePage from "./components/GOButton/ChoosePage.jsx";

export default function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/project" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/Unauthorized" element={<Unauthorized />} />
        <Route path="/button" element={<ChoosePage />} />
        {/* <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/reset-password/:token" element={<ResetPasswordForm/>} /> */}

        <Route
          path="/admin/userlist"
          element={
            <PrivateAuthRoute roles={[ 4]}>
              <Users />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/custom-declarations/:user_id"
          element={
            <PrivateAuthRoute roles={[ 4]}>
              <CustomLists />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/viewdocumentbyid/:user_id"
          element={
            <PrivateAuthRoute roles={[ 2, 3]}>
              <DocumentListByid />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/Admincustomlist"
          element={
            <PrivateAuthRoute roles={[ 4]}>
              <AdminCustomList />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/addcustomer"
          element={
            <PrivateAuthRoute roles={[2, 3]}>
              <AddCustomer />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/Dashboard"
          element={
            <PrivateAuthRoute roles={[ 2, 3]}>
              <Dashboard />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/AdminMenu"
          element={
            <PrivateAuthRoute roles={[4]}>
              <AdminMenu21 />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/user/edit/:id"
          element={
            <PrivateAuthRoute roles={[ 4]}>
              <EditUser />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/user"
          element={
            <PrivateAuthRoute roles={[4]}>
              <UserList />
            </PrivateAuthRoute>
          }
        />
         <Route
          path="/admin/document/edit/:customerId"
          element={
            <PrivateAuthRoute roles={[2,3]}>
              <Custom/>
            </PrivateAuthRoute>
          }
        />
         <Route
          path="/admin/addcustomer/:customerId"
          element={
            <PrivateAuthRoute roles={[2,3]}>
              <Document/>
            </PrivateAuthRoute>
          }
        />
       
       
        <Route
          path="/admin/view-document"
          element={
            <PrivateAuthRoute roles={[4]}>
              <Documents />
            </PrivateAuthRoute>
          }
        />
        <Route
          path="/admin/status/:id"
          element={
            <PrivateAuthRoute roles={[ 4]}>
              <AdminStatus />
            </PrivateAuthRoute>
          }


        />
         <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer /> 
    </>
  );
}
