
const API_URL = "https://tarik.tarikawit.com";

// Function to create a new custom declaration
const createCustomDeclaration = async (declarationData, loggedInCustomerToken) => {
  const options = {
    method: "POST",
    headers: {
      "x-access-token": loggedInCustomerToken,
    },
    body: declarationData,
  };

  const response = await fetch(`${API_URL}/api/custom`, options);
  
  if (!response.ok) {
    const errorText = await response.text(); // Get the error message from the response
    throw new Error(`Error: ${response.status}, Message: ${errorText}`);
  }

  return response;
};


// Function to get all custom declarations
const getAllCustomDeclarations = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(`${API_URL}/api/custom`, requestOptions);
  return response;
};

// Function to get a custom declaration by ID
const getCustomDeclarationById = async (id, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(`${API_URL}/api/custom/${id}`, requestOptions);
  return response;
};
const getCustomDeclarationsByCustomerId = async (customer_id, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(
    `${API_URL}/api/custom/customer/${customer_id}`,
    requestOptions
  );
  return response;
};

// Function to update a custom declaration
const updateCustomDeclaration = async (id, declarationData, token) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(declarationData),
  };

  const response = await fetch(`${API_URL}/api/custom/${id}`, options);
  return response;
};

// Function to delete a custom declaration
const deleteCustomDeclaration = async (id, token) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(`${API_URL}/api/custom/${id}`, requestOptions);
  return response;
};

// Export all the functions
const customDeclarationService = {
  createCustomDeclaration,
  getAllCustomDeclarations,
  getCustomDeclarationById,
  getCustomDeclarationsByCustomerId,
  updateCustomDeclaration,
  deleteCustomDeclaration,
};

export default customDeclarationService;

