const API_URL = "https://tarik.tarikawit.com";

// Function to create a new customer
const createCustomer = async (customerData, loggedInCustomerToken) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": loggedInCustomerToken,
    },
    body: JSON.stringify(customerData),
  };

  const response = await fetch(`${API_URL}/api/customers`, options);
  return response;
};

// Function to get all customers
const getAllCustomers = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(`${API_URL}/api/customers`, requestOptions);
  return response;
};

// Function to get a customer by ID
const getCustomerById = async (customerId, token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(
    `${API_URL}/api/customers/${customerId}`,
    requestOptions
  );
  return response;
};

// Function to update a customer
const updateCustomer = async (id, customerData, token) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(customerData),
  };

  const response = await fetch(`${API_URL}/api/customers/${id}`, options);
  return response;
};

// Function to delete a customer
const deleteCustomer = async (customerId, token) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
  };

  const response = await fetch(
    `${API_URL}/api/customers/${customerId}`,
    requestOptions
  );
  return response;
};

// Export all the functions
const customerService = {
  createCustomer,
  getAllCustomers,
  getCustomerById,
  updateCustomer,
  deleteCustomer,
};

export default customerService;
